

















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { Quarter, QuarterType } from '../../types/quarters'
import { StudentClass, Section } from '../../types/classes'
import SectionComponent from '@/components/Management/Section.vue'
import AddCourseDialog from '@/components/Management/AddCourseDialog.vue'

@Component({
  components: {
    SectionComponent,
    AddCourseDialog,
  },
})
export default class extends Vue {
  private fetchQ = false
  private quarters: { text: string; value: string }[] = []
  private selectedQuarter = ''
  private classes: Partial<StudentClass>[] = []
  private addCourseDialog = false
  private editCourseDialog = false
  private editedCourse: null | Partial<StudentClass> = null

  mounted() {
    this.fetchQuarters()
  }

  @Watch('selectedQuarter')
  onSelectedQuarterChange(val: string) {
    if (val !== '') {
      this.fetchCourse()
    }
  }

  private async fetchQuarters() {
    this.fetchQ = true
    try {
      const res = await this.$apollo.query<{ quarters: Partial<Quarter>[] }>({
        query: gql`
          query FetchQuartersForCourseSelect {
            quarters {
              id
              name
              type
              startDate
              endDate
              isActive
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      const sortedQ = res.data.quarters.sort((a, b) => {
        return (
          new Date(b.startDate || '').getTime() -
          new Date(a.startDate || '').getTime()
        )
      })
      this.quarters = sortedQ.map(el => ({
        text: el.name || '',
        value: el.id + '',
      }))
      const activeQ = sortedQ.find(
        el => el.type === QuarterType.Normal && el.isActive
      )
      this.selectedQuarter = activeQ ? activeQ.id + '' : ''
    } catch (error) {
      // empty
    } finally {
      this.fetchQ = false
    }
  }

  private async fetchCourse() {
    const res = await this.$apollo.query<{ quarter: Partial<Quarter> }>({
      query: gql`
        query ClassInQuarter($id: Int!) {
          quarter(id: $id) {
            classes {
              id
              name
              type
              subject
              sections {
                id
                room {
                  id
                  name
                }
                day
                capacity
                duration
                price
                startTime
                registeredUsers {
                  username
                }
              }
            }
          }
        }
      `,
      variables: {
        id: +this.selectedQuarter,
      },
      fetchPolicy: 'no-cache',
    })
    this.classes = res.data.quarter.classes || []
  }

  private numToDow(dayNum: number) {
    switch (dayNum) {
      case 0:
        return 'Sun'
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      default:
        return 'Und'
    }
  }

  private updateCourse(cr: Partial<StudentClass>) {
    const index = this.classes.findIndex(el => el.id === cr.id)
    if (index > -1) {
      this.$set(this.classes, index, cr)
    } else {
      this.classes.push(cr)
    }
  }

  private updateSection(crId: number, section: Partial<Section>) {
    const thisClass = this.classes.find(el => el.id === crId)
    if (thisClass && thisClass.sections) {
      const index = thisClass.sections.findIndex(el => el.id === section.id)
      if (index > -1) {
        this.$set(thisClass.sections, index, section)
      } else {
        this.$set(thisClass, 'sections', [...thisClass.sections, section])
      }
    }
  }
}
