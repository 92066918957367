





















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Section } from '../../types/classes'
import AddSectionDialog from '@/components/Management/AddSectionDialog.vue'

@Component({
  components: {
    AddSectionDialog,
  },
})
export default class extends Vue {
  @Prop({ default: [] }) sections!: Partial<Section>[]
  @Prop() classId!: number
  @Prop() updateFunc!: (id: number, sect: Partial<Section>) => void

  private addSectionDialog = false
  private editSectionDialog = false
  private editedData: null | Partial<Section> = null

  private numToDow(dayNum: number) {
    switch (dayNum) {
      case 0:
        return 'Sun'
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      default:
        return 'Und'
    }
  }
}
