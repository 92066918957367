















































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { StudentClass } from '../../types/classes'

@Component
export default class extends Vue {
  @PropSync('show', { type: Boolean }) isShow!: boolean
  @Prop({ default: 'new' }) mode!: string
  @Prop() quarterId!: number
  @Prop({ default: null }) oldData!: null | Partial<StudentClass>
  @Prop() updateFunc!: (data: Partial<StudentClass>) => void

  @Watch('isShow')
  onShowChange(val: boolean) {
    if (val && this.mode === 'edit') {
      this.name = this.oldData?.name || ''
      this.subject = this.oldData?.subject || 'math'
    }
  }

  private name = ''
  private subjects = [
    { text: 'Math', value: 'math' },
    { text: 'Physics', value: 'physics' },
    { text: 'Chem', value: 'chem' },
    { text: 'English', value: 'english' },
    { text: 'Sci', value: 'science' },
  ]
  private subject = 'math'
  private onLoad = false

  private submit() {
    if (this.mode === 'new') {
      this.createCR()
    } else if (this.mode === 'edit') {
      this.editCr()
    }
  }

  private async createCR() {
    this.onLoad = true
    try {
      const { data } = await this.$apollo.mutate<{
        createClass: Partial<StudentClass>
      }>({
        mutation: gql`
          mutation CreateNewCourse($data: CreateClassInput!) {
            createClass(data: $data) {
              id
              name
              type
              subject
              sections {
                id
                room {
                  id
                  name
                }
                day
                capacity
                duration
                price
                startTime
                registeredUsers {
                  username
                }
              }
            }
          }
        `,
        variables: {
          data: {
            quarterId: this.quarterId,
            name: this.name,
            subject: this.subject,
          },
        },
      })
      if (data) {
        this.updateFunc(data.createClass)
      }
    } catch (error) {
      // empty
    } finally {
      this.isShow = false
      this.onLoad = false
    }
  }

  private async editCr() {
    if (this.oldData) {
      this.onLoad = true
      try {
        const { data } = await this.$apollo.mutate<{
          editClass: Partial<StudentClass>
        }>({
          mutation: gql`
            mutation EditCourseData($data: EditClassArgs!) {
              editClass(data: $data) {
                id
                name
                type
                subject
                sections {
                  id
                  room {
                    id
                    name
                  }
                  day
                  capacity
                  duration
                  price
                  startTime
                  registeredUsers {
                    username
                  }
                }
              }
            }
          `,
          variables: {
            data: {
              id: this.oldData.id,
              name: this.name,
              subject: this.subject,
            },
          },
        })
        if (data) {
          this.updateFunc(data.editClass)
        }
      } catch (error) {
        // empty
      } finally {
        this.isShow = false
        this.onLoad = false
      }
    }
  }
}
