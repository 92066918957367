















































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { Section } from '../../types/classes'

@Component
export default class extends Vue {
  @PropSync('show', { type: Boolean }) isShow!: boolean
  @Prop() classId!: number
  @Prop({ default: 'new' }) mode!: string
  @Prop({ default: null }) oldData!: null | Partial<Section>
  @Prop() updateFunc!: (id: number, sect: Partial<Section>) => void

  @Watch('isShow')
  onShowChange(val: boolean) {
    if (val && this.mode === 'edit') {
      this.day = this.oldData?.day || 1
      this.startTime = this.oldData?.startTime || 8
      this.duration = this.oldData?.duration + '' || '120'
      this.capacity = this.oldData?.capacity + '' || '0'
      this.price = this.oldData?.price + '' || '7200'
    }
  }

  private days = [
    { text: 'Monday', value: 1 },
    { text: 'Tuesday', value: 2 },
    { text: 'Wednesday', value: 3 },
    { text: 'Thursday', value: 4 },
    { text: 'Friday', value: 5 },
    { text: 'Saturday', value: 6 },
    { text: 'Sunday', value: 0 },
  ]
  private day = 1
  private startTimes = [8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20]
  private startTime = 8
  private duration = '120'
  private capacity = '0'
  private price = '7200'
  private onLoad = false

  private submit() {
    if (this.mode === 'new') {
      this.createSection()
    } else {
      this.editSection()
    }
  }

  private async createSection() {
    this.onLoad = true
    try {
      const { data } = await this.$apollo.mutate<{
        createSection: Partial<Section>
      }>({
        mutation: gql`
          mutation CreateNewSection($data: CreateSectionInput!) {
            createSection(data: $data) {
              id
              room {
                id
                name
              }
              day
              capacity
              duration
              price
              startTime
              registeredUsers {
                username
              }
            }
          }
        `,
        variables: {
          data: {
            classId: this.classId,
            day: this.day,
            capacity: +this.capacity,
            startTime: this.startTime,
            duration: +this.duration,
            price: +this.price,
          },
        },
      })
      if (data) {
        this.updateFunc(this.classId, data.createSection)
      }
    } catch (error) {
      // empty
    } finally {
      this.isShow = false
      this.onLoad = false
    }
  }

  private async editSection() {
    if (this.oldData) {
      this.onLoad = true
      try {
        const { data } = await this.$apollo.mutate<{
          editSection: Partial<Section>
        }>({
          mutation: gql`
            mutation EditSectionData($data: EditSectionInput!) {
              editSection(data: $data) {
                id
                room {
                  id
                  name
                }
                day
                capacity
                duration
                price
                startTime
                registeredUsers {
                  username
                }
              }
            }
          `,
          variables: {
            data: {
              id: this.oldData.id,
              capacity: +this.capacity,
              startTime: this.startTime,
              duration: +this.duration,
              price: +this.price,
            },
          },
        })
        if (data) {
          this.updateFunc(this.classId, data.editSection)
        }
      } catch (error) {
        // empty
      } finally {
        this.isShow = false
        this.onLoad = false
      }
    }
  }
}
